<template>
  <div class="floor">
    <div class="footer-box">
      <h4>关注《东方归言录》掌握最新游戏资讯</h4>

      <div class="logo">
        <!-- <a class="logo1" href="https://www.gg.com/">
          <img
            src="https://thcdn.gggamedownload.com/source/assets/logo.png"
            alt=""
          />
        </a> -->
        <div class="bottom">
          <a class="logo2">
            <img
              src="https://thcdn.gggamedownload.com/source/assets/logo-gsc.png"
              alt=""
            >
          </a>
          <a class="logo3">
            <img
              style="margin-left: 15px"
              src="https://thcdn.gggamedownload.com/source/assets/logo-ninja.png"
              alt=""
            >
          </a>
        </div>
      </div>
      <div class="bom">
        <p>
          东方LostWord是以上海爱丽丝幻乐团的游戏作品「东方Project」的原作为基点的二次创作作品
        </p>
        <p>
          本作品是以上海爱丽丝幻乐团的游戏作品「东方Project」为原点，在获得其许可的基础上，加入了各类创意及设定的作品。
        </p>
        <p>「东方Project」以及相关标题都为上海爱丽丝幻乐团的著作物</p>
      </div>
    </div>
    <div class="floor-link">
      <!-- <span
        ><a target="_blank" href="https://www.gg.com/about.html"
          >关于我们</a
        ></span> -->
      <!-- <span
        ><a target="_blank" href="https://www.gg.com/cooperation.html"
          >商务合作</a
        ></span
      > -->
      <!-- <span
        ><a target="_blank" href="https://www.gg.com/contact.html"
          >联系我们</a
        ></span
      > -->
      <span><router-link to="/protocol">用户协议</router-link></span>
      <span><router-link to="/privacy">隐私协议</router-link></span>
      <span><router-link to="/guardianship">家长监护</router-link></span>
      <p>
        健康游戏忠告：抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防上当受骗
        适度游戏益脑 沉迷游戏伤身 合理安排时间 健康享受生活
      </p>
      <p class="record">
        <a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/index"
        >网站备案号：粤ICP备2021077191号-1</a>
      </p>
      <p>审批文号：国新出审[2021]1185号    出版物号：ISBN978-7-498-09265-6  出版单位： 上海科学技术文献出版社有限公司</p>
      <p>深圳市壹方互娱网络科技有限公司</p>
      <p>公司地址：深圳市龙岗区龙城街道盛平社区深业紫麟山花园H3栋27A25-2 电话: 021-50129861 </p>
      <p>电话： 021-50129861 </p>
      <p>©Team Shanghai Alice</p>
      <p>©GOOD SMILE COMPANY, INC. / NextNinja Co., Ltd.</p>
    </div>
  </div>
</template>
<script>
const link1 = "https://thcdn.gggamedownload.com/source/assets/weibo.png";
const link2 = "https://thcdn.gggamedownload.com/source/assets/QQ.png";
const link3 = "https://thcdn.gggamedownload.com/source/assets/wechat.png";
const link4 = "https://thcdn.gggamedownload.com/source/assets/baidu.png";
const link5 = "https://thcdn.gggamedownload.com/source/assets/bili.png";
export default {
  name: "Floor",
  data() {
    return {
      linkList: [
        {
          id: 1,
          path: link1,
          title: "官方微博",
          link: "@东方归言录",
          url: "https://weibo.com/u/7490517968"
        },
        {
          id: 2,
          path: link2,
          title: "官方QQ群",
          link: "东方归言录",
          url: "https://qm.qq.com/cgi-bin/qm/qr?k=q94FvG_hLsACmYRXF49bDzpKaOsbD73f&jump_from=webapi"
        },
        {
          id: 3,
          path: link3,
          title: "官方公众号",
          link: "东方归言录",
          url: "http://mp.weixin.qq.com/mp/getmasssendmsg?__biz=Mzg5NzQ2MTczNw==#wechat_redirect"
        },
        {
          id: 4,
          path: link4,
          title: "百度贴吧",
          link: "东方归言录吧",
          url: "https://tieba.baidu.com/f?kw=%B6%AB%B7%BD%B9%E9%D1%D4%C2%BC&fr=ala0&tpl=5"
        },
        {
          id: 5,
          path: link5,
          title: "bilibili",
          link: "东方归言录",
          url: "https://space.bilibili.com/1120919518"
        }
      ],
      active_weixinID: null,
      active_QQID: null
    };
  },
  methods: {
    open(url) {
      window.open(url, "_blank")
    }
  }
};
</script>
<style lang="scss" scoped>
.floor {
  height: 78px;
  position: relative;
  bottom: 0;
  text-align: center;
  // background-color: #1b2128;
  color: #4a5663;
  .floor-link {
    cursor: pointer;
    line-height: 30px;
    padding: 18px 5px;
    font-size: 13px;
    background-color: #1b2128;
    a {
      text-decoration: none;
      color: #ffffff;
      padding-left: 5px;
    }
  }
  .footer-box {
    width: 375px;
    text-align: center;
    background-color: #28323c;
    padding-bottom: 26px;
    h4 {
      color: #93a4b1;
      padding-top: 48px;
    }
    .link {
      padding: 0 2px;
      padding-top: 39px;
      img {
        width: 58%;
      }
      ul {
        display: flex;
        justify-content: space-around;
        li {
          cursor: pointer;
          position: relative;
          p {
            color: #fff;
          }
          .p {
            padding-top: 8px;
            color: #93a4b1;
          }

          .hover {
            position: absolute;
            width: 172px;
            top: 0;
            left: 50%;
            transform: translateX(-50%) translateY(-105%);
            text-align: center;
            color: #fff;
            &.QQhover {
              border: 1px solid #fff;
              padding: 10px 0;
            }
            img {
              width: 161px;
            }
          }
        }
      }
    }
    .logo {
      padding-top: 47px;
      a {
        display: inline-block;
      }
      .logo1 {
        width: 208px;
      }
      .logo2 {
        width: 111px;
      }
      .logo3 {
        width: 115px;
      }
      img {
        width: 100%;
      }
      .bottom {
        padding-top: 24px;
      }
    }
    .bom {
      margin-top: 37px;
      cursor: pointer;
      padding: 0 12px;
      padding-top: 22px;
      font-size: 14px;
      color: #93a4b1;
      border-top: 1px solid rgba(120, 143, 161, 0.25);
    }
  }
  p {
    font-size: 12px;
    line-height: 22px;
  }
  .record {
    cursor: pointer;
    a {
      color: #4a5663;
    }
  }
}
</style>
