<template>
  <div class="videos">
    <div class="banner">
      <Head />
      <img src="https://thcdn.gggamedownload.com/source/mobileAssets/ziye/z53.jpg" alt="">
    </div>
    <div class="body">
      <van-tabs v-model="active" animated title-inactive-color="#A9BCCA" title-active-color="#A9BCCA" color="#A9BCCA">
        <van-tab class="video">
          <template #title> 视频中心</template>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="li">
              <van-cell v-for="item in list" :key="item.id" @click="openVideo(item)">
                <div class="mask">
                  <img :src="item.img">
                </div>
                <div class="title">{{ item.title }}</div>
              </van-cell>
            </div>

          </van-list>
        </van-tab>
        <van-tab>
          <template #title> 高清壁纸</template>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="li">
              <van-cell v-for="item in wallpaperList" :key="item.id" @click="openImg(item)">
                <div>
                  <img :src="item.img">
                </div>
                <div class="title">
                  <p>{{ item.title }}</p>
                  <p>作者：{{ item.author }}</p>
                </div>
              </van-cell>
            </div>

          </van-list>
        </van-tab>
      </van-tabs>
    </div>
    <Footer />
    <PlayVideo v-if="showPlayVideo" ref="PlayVideo" :video-data="videoData" />

  </div>
</template>
<script>
import Head from '../components/Head'
import PlayVideo from '../components/PlayVideo'
import Footer from '@/components/MobileFloor'
const bi1 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/girl-a.png'
const bi2 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/pass-a.png'
const bi3 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/speak-a.png'
const bi4 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/dog-a.png'
const bi5 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/ling-a.png'
const more1 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/girl-m.jpg'
const more2 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/pass-m.jpg'
const more3 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/speak-m.jpg'
const more4 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/dog-m.jpg'
const more5 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/ling-m.jpg'
export default {
  name: 'VideoList',
  components: { Head, PlayVideo, Footer },
  data() {
    return {
      active: '',
      showPlayVideo: false,
      videoData: {
        src: ''
      },
      list: [
        {
          id: 1,
          title: 'MV-Project第1弹—《指尖的热度》',
          author: '',
          img: 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/1-MV-2.jpg',
          path: 'https://thcdn.gggamedownload.com/source/MV/1-MV-2.mp4'
        },
        {
          id: 2,
          title: 'MV-Project第2弹《斑驳的木春菊》',
          author: '',
          img: 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/2-MV-1.jpg',
          path: 'https://thcdn.gggamedownload.com/source/MV/2-MV-1.mp4'
        },
        {
          id: 3,
          title: 'MV-Project第3弹—《十六夜与月》',
          author: '',
          img: 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/3-MV-5.jpg',
          path: 'https://thcdn.gggamedownload.com/source/MV/3-MV-5.mp4'
        },
        {
          id: 4,
          title: 'MV-Project第4弹—《白银之风》',
          author: '',
          img: 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/4-MV-4.jpg',
          path: 'https://thcdn.gggamedownload.com/source/MV/4-MV-4.mp4'
        },
        {
          id: 5,
          title: 'MV-Project第5弹—《NAЯAKA》',
          author: '',
          img: 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/5-MV-3.jpg',
          path: 'https://thcdn.gggamedownload.com/source/MV/5-MV-3.mp4'
        }
      ],
      wallpaperList: [
        {
          id: 1,
          title: '飞翔于空中的不可思议巫女',
          author: 'えれっと',
          img: bi1,
          path: more1
        },
        {
          id: 2,
          title: '通行禁止',
          author: '萩原凛',
          img: bi2,
          path: more2
        },
        {
          id: 3,
          title: '无止境的说教',
          author: '山鸟おふう',
          img: bi3,
          path: more3
        },
        {
          id: 4,
          title: '清廉正直的鸦天狗',
          author: 'ヒナユキウサ',
          img: bi4,
          path: more4
        },
        {
          id: 5,
          title: '半灵',
          author: '东方归言录',
          img: bi5,
          path: more5
        }
      ],
      loading: false,
      finished: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    })
  },
  methods: {
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        // this.list = [...this.list, ...this.list]
        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 5) {
          this.finished = true;
        }
      }, 1000);
    },
    openVideo({ path }) {
      this.videoData.src = path
      this.showPlayVideo = true
    },
    openImg({ path }) {
      this.$ImagePreview([
        path
      ]);
    }
  }
}
</script>

<style lang='scss' scoped>

::v-deep {
  .van-tabs__nav {
    justify-content: center;
  }
  .van-tab {
    flex: none;
  }
   .van-tab__text {
      display: inline-block;
      width: 84px;
      height: 22px;
      text-align: center;
      border-radius: 11px;
    }
  .van-tab--active {
    .van-tab__text {
      border: 1px solid #92A2AE;
      display: inline-block;
      width: 84px;
      height: 22px;
      text-align: center;
      border-radius: 11px;
    }
  }
  .van-tabs__line {
    display: none;
  }
}
.banner {
  img {
    width: 100%;
  }
}
.body {

 .li {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   .van-cell {
     width: 160px;
     img {
       width: 100%;
     }
     &::after {
       content: '';
       display: none;
     }
   }
   .title {
     color: #5C6371;
     font-size: 12px;
     line-height: 100%;
     padding-left: 5px;
     border-left: 3px solid #2F3C49;
   }
 }

 .video {
   .li {
     .mask {
       position: relative;
       width: 100%;
       margin-bottom: 5px;
       img {
         width: 100%;
         height: 100% ;
         vertical-align: middle;
       }
       &::after {
         content: '';
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         background-color: rgba(0,0,0,.5);
       }
       &::before {
         content: '';
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translateX(-50%) translateY(-50%);
         width: 95%;
         height: 95%;
         border: 1px solid #E9CD9B;
         background: url('https://thcdn.gggamedownload.com/source/mobileAssets/ziye/y1.png') no-repeat center;
         background-size: 28px 28px;
         z-index: 2;

       }
     }
   }
 }
}
</style>
