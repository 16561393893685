<template>
  <div class="PlayVideo">
    <van-overlay custom-style="color: red;" :show="show">
      <div class="wrapper">
        <div class="block">
          <span class="close" @click="closedVideo">X</span>
          <video id="my-video" class="video-js vjs-default-skin" controls="" preload="none" x5-playsinline="" playsinline="" webkit-playsinline="" poster="" x-webkit-airplay="allow">
            <source :src="videoData.src" type="application/x-mpegURL">
          </video>
        </div>
      </div>
    </van-overlay>
  </div>

</template>

<script>
import 'video.js/dist/video-js.css'
import videojs from 'video.js'
export default {
  name: 'PlayVideo',
  props: {
    videoData: {
      type: Object,
      default: () => {
        return {
          src: ''
        }
      }
    }
  },
  data() {
    return {
      show: true,
      myPlayer: ''
    }
  },
  mounted() {
    this.addLanguage()
    this.initVideo()
  },
  destroyed() {
    this.myPlayer.dispose()
  },
  methods: {

    addLanguage() {
      videojs.addLanguage('zh-CN', {
        "You aborted the media playback": "视频播放被终止",
        "A network error caused the media download to fail part-way.": "网络错误导致视频下载中途失败。",
        "The media could not be loaded, either because the server or network failed or because the format is not supported.": "视频因格式不支持或者服务器或网络的问题无法加载。",
        "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "由于视频文件损坏或是该视频使用了你的浏览器不支持的功能，播放终止。",
        "No compatible source was found for this media.": "无法找到此视频兼容的源。"
      })
    },
    initVideo() {
      var newUrl = this.videoData.src;
      this.myPlayer = videojs('my-video', {
        bigPlayButton: true, // 是否显示播放按钮（这个播放按钮默认会再左上方，需用CSS设置居中）
        textTrackDisplay: true,
        posterImage: true,
        errorDisplay: true,
        controlBar: true,
        autoplay: true, // 这个说是自动播放，但是本人使用无效
        controls: true
      }, function() {
        this.play()
      })

      this.myPlayer.src(newUrl);
      this.myPlayer.load(newUrl);
    },
    closedVideo() {
      this.$parent.showPlayVideo = false
    }
  }
}
</script>

<style lang="scss" scoped>
.PlayVideo {
  width: 100%;
  height: 100%;
}
.video-js {
  width: 100% !important;
}
.van-overlay {
  z-index: 1000;
}
::v-deep {

.vjs-big-play-button{
	left:50% !important;
	top: 50% !important;
transform: translateX(-50%) translateY(-50%);

  .my-video-dimensions {
      height: 100% ;
    }
}
}
 .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    position: relative;
    width: 100%;
    height: 158px;
    .my-video-dimensions {
      height: 100% ;
    }
    .vjs-tech {
      height: 100% ;
    }
    .close {
      position: absolute;
      top: 0;
      font-size: 30px;
      transform: translateY(-100%) translateX(-50%);
      right: 0;
      z-index: 99;
      color: #fff;

    }
  }
</style>
